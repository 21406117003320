import UserConnector from "../connectors/UserConnector";
import TransportInterface from "../transport/TransportInterface";
import BgServerStarz from "~/modules/starz/BgServerStarz";


export default class UserConnectorStarz extends UserConnector {
    server:BgServerStarz

    constructor(socket:SocketIOClient.Socket, transport:TransportInterface, server:BgServerStarz)  {
        super(socket,transport)
        this.server = server;
        this.rpcUrl = '/starz-rpc';
    }

    async doLoginStarz(token:string) {
        return this.__call("login-starz", {token: token});
    };

    async getActualData() {
        return await this.__call(/*'user-info-get'*/ 'get-external-account', {})
      };

    async doPollingAccount() {
        setTimeout(
            async () => {
                const newAccountMainData = await this.getActualData() // waiting of finish of async function
                //console.log('POLLING', newUserData)
                // update vuex state or send update event to event bus
                if (newAccountMainData && newAccountMainData.account)
                    this.server.changeAccount(newAccountMainData.account)
                this.doPollingAccount()
            }, 
            10000
        )
    }
}
